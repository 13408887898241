<template>
    <div class="main">
        <MenuApp></MenuApp>
        <div class="params-container" v-if="params">
            <div class="winrate-container">
                <label>Winrate (en %)</label>
                <input type="number" step="0.1" min="0.1" max="100" v-model="winrate" @keyup="changeWinrate"/>
            </div>
            <div class="price-container">
                <label>Remaining Big Prizes</label>
                <div class="price-number">{{ params.big_price}}</div>
            </div>
            <div class="price-container">
                <label>Remaining Small Prizes</label>
                <div class="price-number">{{ params.small_price}}</div>
            </div>
            <div class="price-container">
                <label>Wheel turns</label>
                <div class="price-number">{{wheel}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import store from './../store'
import axios from 'axios'
import MenuApp from './../components/MenuApp'
export default {
    name :'Parameters',
    components : {
        MenuApp
    },
    data() {
        return {
            params : {},
            winrate : null,
            wheel : null
        }
    },
    mounted() {
        let config = {
            validateStatus: () => true,
        };
        
        config.headers = { Authorization: `Bearer ${store.state.JWT_TOKEN}` }
        
        let vm = this

        axios
            .get(store.getters.getURL +'params', config)
            .then(function (response) {
                if(response.status === 200) {
                    vm.params = response.data.params
                    vm.winrate = response.data.params.winrate
                    vm.wheel = response.data.wheel
                } else {
                    store.dispatch('addNotif', {type : 2, content : response.data.message})
                }
                })
            .catch((res) => {
                store.dispatch('addNotif', {type : 2, content : res})
            });
    },
    methods: {
        changeWinrate() {
                    let config = {
            validateStatus: () => true,
        };
        
        config.headers = { Authorization: `Bearer ${store.state.JWT_TOKEN}` }

        let params = {
            id : this.params.parameters_id,
            winrate : this.winrate
        }

        axios
            .put(store.getters.getURL +'params', params, config)
            .then(function (response) {
                if(response.status != 200) {
                    store.dispatch('addNotif', {type : 2, content : response.data.message})
                } 
            })
            .catch((res) => {
                store.dispatch('addNotif', {type : 2, content : res})
            });
        }
    }
}
</script>

<style scoped>
    .params-container {
        margin: 40px auto;
        width:400px;
        max-width: 90%;
    }

    .params-container label {
        font-family: 'Arimo';
        color:#a4a5a6;        
    }

    .params-container input {
        border: 1px solid #d6d6d6;
        width: 150px;
        padding: 10px 15px;
        border-radius: 20px;
        font-family: 'Arimo';
    }

    .params-container input:focus {
        outline: none;
    }

    .winrate-container, .price-container {
        display:flex;
        justify-content: space-between;
        align-items:center;
        margin-bottom:20px;
    }

    .price-number {
        font-family: 'Arimo_bold';
        font-size:18px;
        color:#ef7941;
    }
</style>